import React, { FC } from 'react';
import { graphql, Link } from 'gatsby';

import DangerouslySetInnerHtml from 'layout/DangerouslySetInnerHtml';
import Layout from 'layout/Layout';
import Image from 'common/Image';
import PageSchema from 'common/PageSchema';
import Seo from 'common/Seo';

import { filterByTagId } from '../../utils/functions';
import { ArticleProps } from './models';

import './Article.scss';

const Article: FC<{ data: ArticleProps }> = ({
  data: {
    article: { urls, seo, title, body, imageDesktop, imageMobile, tags, headband },
    allArticle: { nodes: items },
    global: { read },
  },
}) => {
  const { title: seoTitle, keywords: seoKeywords, description: seoDescription } = seo;

  const articles = filterByTagId(items, tags.id, 4);

  return (
    <Layout headerTransparent {...{ headband }}>
      <Seo {...{ urls, seo }} />
      <PageSchema
        type="WebPage"
        name={seoTitle}
        data={{
          metaTitle: seoTitle,
          metaDescription: seoDescription,
          metaKeywords: seoKeywords,
        }}
      />
      <div className={`article article__${tags.name}`}>
        <div className="article__banner">
          <Image
            imageData={imageDesktop}
            alt={imageDesktop.altText}
            className="d-none d-md-block"
          />
          <Image imageData={imageMobile} alt={imageMobile.altText} className="d-block d-md-none" />
        </div>
        <div className="article__content px-4 mx-5">
          <div className="article__title text-center pt-5">
            <h2 className="mx-4 py-5 mt-5">{title}</h2>
          </div>
          <div className="article__body pt-5">
            <DangerouslySetInnerHtml html={body} element="div" />
          </div>
        </div>
        <div className="article__listing px-5">
          <div className="container-fluid">
            <div className="row">
              {articles.map(({ id, url, thumbnail, title: name }) => (
                <div className="col" key={id}>
                  <div
                    className={`article-list__article mb-4 mx-auto text-center article-list__article__${tags.name}`}
                  >
                    <Link to={url} className="d-block">
                      <Image imageData={thumbnail} alt={thumbnail.altText} />
                    </Link>
                    <h4 className="pt-3">{name}</h4>
                    <Link
                      type="button"
                      className="p-2 border-0 d-flex align-items-center justify-content-center"
                      aria-label={name}
                      to={url}
                    >
                      {read}
                    </Link>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query ($url: String) {
    article(url: { eq: $url }) {
      urls {
        lang
        href
      }
      headband {
        cta {
          url
          name
        }
        imageDesktop {
          name
          childImageSharp {
            gatsbyImageData
          }
        }
        imageMobile {
          name
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      seo {
        ...SEOStructureFragment
      }
      title
      body
      tags {
        id
        name
      }
      imageDesktop {
        altText
        fallbackUrl
        desktop {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 1920)
          }
        }
        mobile {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 1920)
          }
        }
        tablet {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 1920)
          }
        }
      }
      imageMobile {
        altText
        fallbackUrl
        desktop {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 700)
          }
        }
        mobile {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 700)
          }
        }
        tablet {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 700)
          }
        }
      }
    }
    global {
      read
    }
    allArticle {
      nodes {
        id
        title
        url
        tags {
          id
          name
        }
        thumbnail {
          altText
          fallbackUrl
          desktop {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 220)
            }
          }
          mobile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 220)
            }
          }
          tablet {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 220)
            }
          }
        }
      }
    }
  }
`;

export default Article;
